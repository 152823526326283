
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';

import UserService, { UserServiceS } from '@/modules/user/user.service';

import type Day from '@/modules/common/types/day.type';
import COMPSET_TYPE from '@/modules/compsets/constants/compset-type.constant';
import PRICE_SHOWN from '@/modules/rates/constants/price-shown.constant';

import ModalWrapper from '@/modules/common/components/modal-wrapper.vue';
import PopupEventsContainer from '@/modules/events/components/popup-events-container.vue';
import DayChanger from '@/modules/common/components/day-changer.vue';
import CompsetScale from '@/modules/common/components/compset-scale.vue';
import JsonViewer from '@/modules/common/components/ui-kit/json-viewer.vue';

import Demand from '@/modules/common/components/ui-kit/demand/index.vue';
import Occupancy from '@/modules/common/components/ui-kit/occupancy/index.vue';
import RatesScanBtn from '@/modules/common/modules/rates/components/rates-scan-btn.vue';

import TogglePrices from '@/modules/document-filters/components/toggle-prices.vue';

export interface ColumnData<T, B extends keyof T = keyof T> {
    label: string;
    field: B;
    width?: number | string;
    onHover?: (e: MouseEvent, data: T, col: ColumnData<T, B>) => void,
    onLeave?: (e: MouseEvent, data: T, col: ColumnData<T, B>) => void,

    /** Disabls v-text */
    noText?: boolean;

    component?: (value: T[B], item: T) => {
        is: string | Vue,
        [k: string]: any,
    },
}

export interface TableData {
    isCurrentHotel: boolean;
    isCompset: boolean;
    tooltip?: {
        [field: string]: string;
    };
}

@Component({
    components: {
        ModalWrapper,
        TogglePrices,
        PopupEventsContainer,
        DayChanger,
        CompsetScale,
        JsonViewer,
        Demand,
        Occupancy,
        RatesScanBtn,
    },
})
export default class RatesDayPopup<TableData> extends Vue {
    @Inject(UserServiceS) userService!: UserService;

    showRawData = false;
    baseClass = '';

    get message() {
        return null;
    }

    get modalWidth() {
        return 880;
    }

    get tableData(): TableData[] {
        return [];
    }

    get textAboveMetrics() {
        return '';
    }

    get columns(): ColumnData<TableData>[] {
        return [];
    }

    get demand(): number | null {
        return 0;
    }

    get occupancy(): number | null {
        return 0;
    }

    get competitionPercent(): number | null {
        return 0;
    }

    get compsetType(): COMPSET_TYPE {
        return COMPSET_TYPE.MEDIAN;
    }

    set priceShown(value: PRICE_SHOWN) {
        // eslint-disable-next-line no-console
        console.warn('[RatesDayPopup]', 'Override priceShown getter/setter in the component', value);
    }

    get priceShown() {
        return PRICE_SHOWN.SHOWN;
    }

    get isPriceHistoryAvailable() {
        return true;
    }

    get compsetTypeLabel() {
        const { compsetType } = this;

        return true
            && compsetType
            && this.$t(`filterSettings.compset_compr.${compsetType}`);
    }

    get dayColor() {
        return { green: true } as Partial<{
            green: boolean;
            red: boolean;
            grey: boolean;
            yellow: boolean;
        }>;
    }

    get showPriceDiff() {
        return true;
    }

    get day() {
        return (+this.$route.params.day || 1) as Day;
    }

    get rawData(): any {
        return {};
    }

    get compsetDataType() {
        return {
            isNA: false,
            isSoldOut: false,
            isNoData: false,
        };
    }

    get scanButtonProps(): any {
        return {};
    }

    // NOTE: Place here is-like getters

    get isAnalysisMode() {
        const routeName = this.$route.name || '';

        return routeName.includes('.analysis');
    }

    get isPriceToggleActive() {
        return true;
    }

    get isAllChannelsMode() {
        return false;
    }

    get isLoading() {
        return false;
    }

    get isTableDataAvailable() {
        return true;
    }

    get isOutOfRange() {
        return false;
    }

    get isNoData() {
        return false;
    }

    get isSuperadmin() {
        return this.userService.isSuperadmin;
    }

    get averagePrice() { return 0; }
    get lowestPrice() { return 0; }
    get highestPrice() { return 0; }

    openPriceHistory() {}

    onTriggerScan() {}

    toggleJsonView() {
        if (!this.isSuperadmin) return;

        this.showRawData = !this.showRawData;
    }

    handleCellHover(e: MouseEvent, data: TableData, col: ColumnData<TableData>) {
        if (col.onHover) {
            col.onHover(e, data, col);
        }
    }

    handleCellLeave(e: MouseEvent, data: TableData, col: ColumnData<TableData>) {
        if (col.onLeave) {
            col.onLeave(e, data, col);
        }
    }

    resolveValue<T extends Record<string, any>>(data: T, field: string) {
        if (!field) return undefined;

        if (typeof field === 'function') {
            return (field as Function)(data);
        }

        return field.split('.').reduce((acc, key) => (acc ? acc[key] : acc), data);
    }
}

