var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ModalWrapper',{class:[_vm.baseClass, 'rates-day-common-popup'],style:({
        '--modal-width': _vm.modalWidth + 'px',
    }),attrs:{"add-class":_vm.isAnalysisMode ? 'analysis-popup' : ''},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"popup-header"},[_c('DayChanger'),_c('div',{staticClass:"on-demand"},[_c('RatesScanBtn',_vm._b({attrs:{"showScanDate":true},on:{"onTriggerScan":_vm.onTriggerScan}},'RatesScanBtn',_vm.scanButtonProps,false))],1)],1)]},proxy:true},{key:"main",fn:function(){return [(_vm.isOutOfRange && !_vm.isLoading)?_c('div',{class:{
                'main main--out-of-range': true,
                'skeleton-container': _vm.isLoading,
            }},[_c('div',{class:{
                    'out-of-range': true,
                    'skeleton skeleton--gray': _vm.isLoading,
                }},[_c('span',[_vm._v(" This Date is out of the scanned range, scan can be "),_c('span',{class:{
                            'out-of-range__btn': true,
                            'disabled': !_vm.isScanAvailable
                        },domProps:{"textContent":_vm._s('On Demand')},on:{"click":function($event){return _vm.onTriggerScan(_vm.day)}}})])])]):_c('div',{staticClass:"main",class:{ 'skeleton-container': _vm.isLoading }},[(_vm.textAboveMetrics)?_c('div',{staticClass:"main__hint",domProps:{"textContent":_vm._s(_vm.textAboveMetrics)}}):_vm._e(),_c('div',{class:{
                'metrics': true,
                'skeleton-container': _vm.isLoading,
            }},[_c('div',{class:{
                        'row': true,
                        'skeleton skeleton--gray': _vm.isLoading
                    }},[(!_vm.isAllChannelsMode)?[(_vm.showPriceDiff)?_c('CompsetScale',{staticClass:"metrics__item",attrs:{"color":_vm.dayColor,"compsetDataType":_vm.compsetDataType},scopedSlots:_vm._u([{key:"value",fn:function(){return [_vm._v(" "+_vm._s(_vm.competitionPercent || 0)+"% ")]},proxy:true},(!_vm.isAnalysisMode)?{key:"type",fn:function(){return [_vm._v(" from compset "+_vm._s(_vm.compsetTypeLabel)+" ")]},proxy:true}:null],null,true)}):_vm._e(),(_vm.occupancy)?_c('Occupancy',{staticClass:"metrics__item",attrs:{"type":"extended","value":_vm.occupancy}}):_vm._e(),(_vm.demand)?_c('Demand',{staticClass:"metrics__item",attrs:{"type":"extended","value":_vm.demand}}):_vm._e()]:[_c('CompsetScale',{attrs:{"compsetDataType":_vm.compsetDataType,"color":_vm.dayColor},scopedSlots:_vm._u([{key:"value",fn:function(){return [_vm._v(" "+_vm._s(_vm.averagePrice)+" ")]},proxy:true},{key:"type",fn:function(){return [_vm._v(" Average ")]},proxy:true}])}),(!_vm.compsetDataType.isNoData && !_vm.compsetDataType.isSoldOut)?_c('div',{staticClass:"metrics__item"},[_c('div',{staticClass:"price"},[_vm._v(_vm._s(_vm.highestPrice))]),_c('div',{staticClass:"text"},[_vm._v("Highest price")])]):_vm._e(),(!_vm.compsetDataType.isNoData && !_vm.compsetDataType.isSoldOut)?_c('div',{staticClass:"metrics__item"},[_c('div',{staticClass:"price"},[_vm._v(_vm._s(_vm.lowestPrice))]),_c('div',{staticClass:"text"},[_vm._v("Lowest price")])]):_vm._e()]],2)]),_c('div',{staticClass:"prices",class:{
                'skeleton-container': _vm.isLoading,
            }},[(!_vm.isTableDataAvailable && !_vm.isLoading)?_c('div',{staticClass:"prices__no-data"},[_vm._v(" No data available for the selected filters ")]):_c('table',{staticClass:"prices-table"},[(!_vm.showRawData)?_c('thead',{staticClass:"prices-table__header"},[_c('tr',_vm._l((_vm.columns),function(col){return _c('th',{key:col.field,class:{
                                    'prices-table__cell': true,
                                    ['prices-table__cell--' + col.field]: true,
                                },attrs:{"width":col.width},domProps:{"textContent":_vm._s(col.label)}})}),0)]):_vm._e(),_c('tbody',[(_vm.isTableDataAvailable && !_vm.showRawData)?_vm._l((_vm.tableData),function(item,i){return _c('tr',{key:i,class:{
                                    'prices-table__row': true,
                                    'prices-table__row--me': item.isCurrentHotel,
                                    'prices-table__row--compset': item.isCompset
                                }},_vm._l((_vm.columns),function(col){return _c('td',{key:col.field + '-' + i,class:{
                                        'prices-table__cell': true,
                                        ['prices-table__cell--' + col.field]: true,
                                    },attrs:{"title":item.tooltip ? item.tooltip[col.field] : undefined},on:{"mouseover":function($event){return _vm.handleCellHover($event, item, col)},"mouseleave":function($event){return _vm.handleCellLeave($event, item, col)}}},[_c('span',{class:{ skeleton: _vm.isLoading }},[(col.component)?[_vm._l(([col.component(_vm.resolveValue(item, col.field), item)]),function(componentData){return [(componentData.text)?[(!componentData.textAsSlot)?_c('component',_vm._b({domProps:{"textContent":_vm._s(componentData.text)}},'component',componentData,false)):_c('component',_vm._b({},'component',componentData,false),[_vm._v(" "+_vm._s(componentData.text)+" ")])]:_c('component',_vm._b({},'component',componentData,false))]})]:_c('span',{attrs:{"title":_vm.resolveValue(item, col.title)},domProps:{"textContent":_vm._s(_vm.resolveValue(item, col.field))}}),_c('span')],2)])}),0)}):_vm._e(),(_vm.showRawData)?[_c('JsonViewer',{attrs:{"value":_vm.rawData,"day":_vm.day}})]:_vm._e()],2)])]),_c('div',{staticClass:"price-history-link"},[(_vm.isPriceToggleActive)?_c('TogglePrices',{model:{value:(_vm.priceShown),callback:function ($$v) {_vm.priceShown=$$v},expression:"priceShown"}}):_vm._e(),(_vm.isSuperadmin)?_c('a',{staticClass:"link",on:{"click":_vm.toggleJsonView}},[_vm._v(" "+_vm._s(_vm.showRawData ? 'To normal view' : 'Show raw data')+" ")]):_vm._e(),(_vm.isPriceHistoryAvailable)?_c('a',{staticClass:"link",on:{"click":_vm.openPriceHistory}},[_vm._v(" View Price History ")]):_vm._e()],1)]),_c('PopupEventsContainer',{attrs:{"day":_vm.day}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }